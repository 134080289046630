export default {
  public: [],
  admin: [
    // 商品列表
    {
      path: 'product',
      name: 'product',
      component: () => import('@/modules/product/views/productList.vue'),
    },

    // 商品表單
    {
      path: 'product/create',
      name: 'product-create',
      meta: {
        formMode: 'create',
      },
      component: () => import('@/modules/product/views/productForm.vue'),
    },
    {
      path: 'product/update/:target',
      name: 'product-update',
      meta: {
        formMode: 'update',
      },
      component: () => import('@/modules/product/views/productForm.vue'),
    },

    {
      path: `product-category`,
      name: `product-category`,
      component: () => import('@/modules/product/views/productCategoryTree.vue'),
    }
  ],
}
